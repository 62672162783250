// https://htmldom.dev/drag-to-scroll/
var ScrollableRow=document.querySelector('#ScrollableRow')
var images=ScrollableRow.querySelectorAll('.ImgCompare')
window['currentScrollInd']=0
window['scrollRowRight']=function scrollRowRight() {
  ScrollableRow.scrollBy({
    left: 100,
    behavior:'smooth',
  })
  // const candidate=window['currentScrollInd']+1
  // var image=images[candidate]
  // if(image) {

  //   // block:'nearest',behavior:'smooth'
  //   // image.scrollIntoView({inline:'start',})
  //   // window['currentScrollInd']=candidate
  // }
}
window['scrollRowLeft']=function scrollRowLeft() {
  ScrollableRow.scrollBy({
    left: -100,
    behavior:'smooth',
  })
}